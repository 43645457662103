import { render, staticRenderFns } from "./BagBest.vue?vue&type=template&id=0082524f"
import script from "./BagBest.vue?vue&type=script&lang=js"
export * from "./BagBest.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports