<template>
  <div class="product_detail">
    <div class="detail_container">
      <div class="detail_img">
              <img :src="product.img">
      </div>
      <div class="detail_content">
        <h1>{{product.name}}</h1>
        <p class="price">판매가: {{ product.price }}원</p>
        <div class="detail_info">
          <tbody>
          <tr>
            <th><p class="price">판매가</p></th>
            <td><p class="price">{{product.price | formatNumber}}원</p></td>
          </tr>
          <tr>
            <th><p>국내/해외배송</p></th>
            <td><p>해외배송</p></td>
          </tr>
          <tr>
            <th><p>배송방법</p></th>
            <td><p>택배</p></td>
          </tr>
          <tr>
            <th><p>배송비</p></th>
            <td><p>{{product.delivery | formatNumber}}원</p></td>
          </tr>
          </tbody>
        </div>
        <div class="totalProducts">
          <p>{{ product.title }}</p>
          <div class="quantity_control">
            <button @click="decreaseQuantity">-</button>
            <span>{{ quantity }}</span>
            <button @click="increaseQuantity">+</button>
          </div>
          <p class="total_price">총 가격: {{ totalPrice | formatNumber}}원</p>
        </div>
        <div class="totalPrice">
          <p class="title">TOTAL <span>(QUANTITY)</span></p>
          <p class="total">{{ totalPrice | formatNumber }}원<span> ({{ quantity }}개)</span> </p>
        </div>
        <div class="btn_box">
          <button @click="setCartItem" class="cart">장바구니 담기</button>
          <button>바로 구매</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import watchItems from '../../constants/watch_item';
  import bagItems from '../../constants/bag_item';
  import shoesItems from '../../constants/shoes_item';
  import topItems from '../../constants/top_item';
  import walletItems from '../../constants/wallet_item';
  import accItems from '../../constants/acc_item';
  import { getProductData } from "../../common/api/product";

  export default {
    name: "CategoryDetail",
    data() {
      return {
        product: {},
        quantity: 1,
      };
    },
    computed: {
      totalPrice() {
        return this.quantity * this.product.price + this.product.delivery;
      },
    },
    methods: {
      async getData() {
        try {
          const data = await getProductData();
          console.log('test1111', data);
          this.product = data
        } catch (error) {
          console.error("상품 데이터를 가져오는 중 오류 발생:", error);
        }
      },
      setCartItem(){
        const cart = JSON.parse(localStorage.getItem('CartItem')) || [];
        const cartItem = {
          id: this.product.id,
          category: this.$route.params.category,
          title: this.product.title,
          img: this.product.img,
          price: this.product.price,
          quantity: this.quantity,
          totalPrice: this.totalPrice,
          delivery: this.product.delivery
        };

        const existingItemIndex = cart.findIndex( item => item.id === cartItem.id && item.category === cartItem.category);

        if (existingItemIndex !== -1) {
          cart[existingItemIndex].quantity += this.quantity;
          cart[existingItemIndex].totalPrice += this.totalPrice;
        } else {
          cart.push(cartItem);
        }

        localStorage.setItem('CartItem', JSON.stringify(cart));

        this.$confirm('장바구니에 상품이 담겼습니다! 장바구니로 이동하시겠습니까?')
        .then(()=>{
          this.$router.push('/cart_view')
        })
        .catch(()=>{
          return
        })
      },
      increaseQuantity() {
        this.quantity++;
      },
      decreaseQuantity() {
        if (this.quantity > 1) {
          this.quantity--;
        }
      },
      fetchProductData(category, id) {
        const dataMap = {
          watch: watchItems,
          bag: bagItems,
          shoes: shoesItems,
          top: topItems,
          wallet: walletItems,
          acc: accItems,
        };

        const categoryData = dataMap[category];
        if (categoryData) {
          this.product = categoryData.find(item => item.id == id);
        } else {
          console.error('errrrrr:', category);
        }
      },
    },
    created() {
      const category = this.$route.params.category;
      const id = this.$route.params.id;
      this.fetchProductData(category, id);
      this.getData();
    },
  };
</script>