const GetNotice = [
  {
    id: 1,
    title: '안녕하세요.',
    content: '계속업뎃중!~!',
    createDate:'2024.12.15'
  },
  {
    id: 2,
    title: '구매방법 ',
    content: '잘~',
    createDate:'2024.12.15'
  },
  {
    id: 3,
    title: '3',
    content: '3',
    createDate:'2024.12.15'
  },
];

export default GetNotice;