<template>
  <div class="tradeCompView" id="Support_Contact">
    <div class="Contact_Container">
      <button class="learn-more">카카오톡 문의</button>
      <button class="learn-more">인스타 문의</button>
      <button class="learn-more">텔레그램 문의</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceContact"
  }
</script>