const AdminDashboard = ()=> import('../../admin/AdminDashboard')
const ProductManagement = ()=> import('../../admin/ProductManagement')
const ProductSave = ()=> import('../../admin/ProductSave')
const ProductContent = ()=> import('../../admin/ProductContent')
const OrderManagement = ()=> import('../../admin/OrderManagement')
const UserManagement = ()=> import('../../admin/UserManagement')

export default [
  {
    path: '/admin/dashboard', name: 'AdminDashBoard', component: AdminDashboard,
    meta: { requiredRole: true, layout: 'Admin', title: '대시보드' }
  },
  {
    path: '/admin/product/management', name: 'ProductManagement', component: ProductManagement,
    meta: { requiredRole: true, layout: 'Admin', title: '상품관리' }
  },
  {
    path: '/admin/product/save/:params?', name: 'ProductSave', component: ProductSave,
    meta: { requiredRole: true, layout: 'Admin', title: '상품등록/수정' }
  },
  {
    path: '/admin/product/content/:params?', name: 'ProductContent', component: ProductContent,
    meta: { requiredRole: true, layout: 'Admin', title: '상품상세' }
  },
  {
    path: '/admin/order/management', name: 'OrderManagement', component: OrderManagement,
    meta: { requiredRole: true, layout: 'Admin', title: '주문관리' }
  },
  {
    path: '/admin/user/management', name: 'UserManagement', component: UserManagement,
    meta: { requiredRole: true, layout: 'Admin', title: '회원관리' }
  },
]