<template>
  <div>
    user
  </div>
</template>

<script>
  export default {
    name: "UserManagement"
  }
</script>
