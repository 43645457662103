<template>
  <div>
    order
  </div>
</template>

<script>

  export default {
    name: "OrderManagement",
    components:{},
    data() {
      return {

      };
    },
  }
</script>