<template>
  <div>
    ds
  </div>
</template>

<script>
  export default {
    name: "CartComp"
  }
</script>