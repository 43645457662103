<template>
  <div id="DashbordManage">
    <MyChart class="chart"
        :chartData="sales_chartData"
        :chartOptions="chartOptions"
        chartType="line"
        name="판매량"
    />
    <div class="slice_chart">
      <MyChart class="chart"
          :chartData="user_chartData"
          :chartOptions="chartOptions"
          chartType="line"
          name="회원수"
      />
      <MyChart class="chart"
          :chartData="proceeds_chartData"
          :chartOptions="chartOptions"
          chartType="bar"
          name="매출량"
      />
    </div>
  </div>
</template>

<script>
  import MyChart from "./ChartCard";

  export default {
    name: "DashbordManage",
    components:{MyChart},
    data() {
      return {
        sales_chartData: {
          labels: ["1월", "2월", "3월", "4월", "5월", "6월", '7월','8월','9월','10월','11월','12월'],
          datasets: [
            {
              label: "판매량",
              backgroundColor: "rgba(75,192,192,0.03)",
              borderColor: "rgba(75,192,192,1)",
              data: [65, 59, 80, 81, 56, 55],
              pointBackgroundColor: "#fff", // 점 색상
              pointBorderColor: "#48DBFB", // 점 테두리 색상
              pointRadius: 5, // 점 크기
              pointHoverRadius: 7, // 호버 시 점 크기
              borderWidth: 2, // 선 두께
            },
          ],
        },
        user_chartData: {
          labels: ["1월", "2월", "3월", "4월", "5월", "6월", '7월','8월','9월','10월','11월','12월'],
          datasets: [
            {
              label: "회원수",
              backgroundColor: "rgba(75,192,192,0.03)",
              borderColor: "rgba(75,192,192,1)",
              data: [65, 59, 80, 81, 56, 55],
              pointBackgroundColor: "#fff", // 점 색상
              pointBorderColor: "#48DBFB", // 점 테두리 색상
              pointRadius: 5, // 점 크기
              pointHoverRadius: 7, // 호버 시 점 크기
              borderWidth: 2, // 선 두께
            },
          ],
        },
        proceeds_chartData: {
          labels: ["1월", "2월", "3월", "4월", "5월", "6월", '7월','8월','9월','10월','11월','12월'],
          datasets: [
            {
              label: "매출량",
              backgroundColor: "rgba(75,192,192,0.03)",
              borderColor: "rgba(75,192,192,1)",
              data: [65, 59, 80, 81, 56, 55],
              pointBackgroundColor: "#fff", // 점 색상
              pointBorderColor: "#48DBFB", // 점 테두리 색상
              pointRadius: 5, // 점 크기
              pointHoverRadius: 7, // 호버 시 점 크기
              borderWidth: 2, // 선 두께
            },
          ],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
  }
</script>