<template>
  <div id="Cart">
    <div class="cart_container" v-if="cart.length>0">
      <div class="cart_main">
        <p class="p1">장바구니 상품</p>
        <div class="cart_total">
          <p>일반상품 ({{cart.length}})</p>
        </div>
        <div class="cart_item" v-for="i in cart" :key="i.id">
          <div class="item_box">
            <input type="checkbox" v-model="i.selected" />
            <img :src="i.img">
            <div class="cart_info">
              <p>{{ i.name }}</p>
              <p>{{ i.price | formatNumber }}원</p>
              <p>{{i.delivery | formatNumber}}원</p>
            </div>
            <i @click="RemoveItem(i)" class="el-icon-close"></i>
          </div>
          <div class="quantity_box">
            <p>수량</p>
            <div class="quantity_control">
              <button @click="decreaseQuantity(i)">-</button>
              <span>{{ i.quantity }}</span>
              <button @click="increaseQuantity(i)">+</button>
            </div>
          </div>
          <div class="total_price">
            <p class="p3">주문금액</p>
            <p class="p4">{{i.totalPrice | formatNumber}}원</p>
          </div>
          <div class="button_box">
            <button>관심상품</button>
            <button class="btn2">주문하기</button>
          </div>
        </div>
      </div>
      <div class="cart_side">
        <div class="side_container">
          <div class="total_box">
            <div class="totalSummary__item">
              <p>총 상품금액</p>
              <p>{{ selectedTotalPrice | formatNumber }}원</p>
            </div>
            <div class="totalSummary__item">
              <p>총 배송비</p>
              <p>{{ totalDelivery | formatNumber }}원</p>
            </div>
            <div class="total">
              <p>결제예정금액</p>
              <p>{{ finalTotalPrice | formatNumber }}원</p>
            </div>
          </div>
          <div class="btn_box">
            <button class="btn2" @click="selectAll">전체상품주문</button>
            <button @click="processSelected">선택상품주문</button>
          </div>
        </div>
      </div>
    </div>
    <div class="noitem" v-else>
      <div class="noitem_container">
        <i class="el-icon-warning-outline"></i>
        <p>장바구니가 비어 있습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Cart",
    data() {
      return {
        cart: [],
      }
    },
    computed: {
      selectedTotalPrice() {
        return this.cart
        .filter((item) => item.selected)
        .reduce((sum, item) => sum + item.totalPrice, 0);
      },
      totalDelivery() {
        return this.cart
        .filter(item => item.selected)
        .reduce((sum, item) => sum + item.delivery, 0);
      },
      finalTotalPrice() {
        return this.selectedTotalPrice + this.totalDelivery;
      }
    },
    methods: {
      increaseQuantity(item) {
        item.quantity += 1;
        item.totalPrice = item.quantity * item.price;
        localStorage.setItem('CartItem', JSON.stringify(this.cart));
      },
      decreaseQuantity(item) {
        if (item.quantity > 1) {
          item.quantity -= 1;
          item.totalPrice = item.quantity * item.price;
          localStorage.setItem("CartItem", JSON.stringify(this.cart));
        } else {
          this.$alert("수량은 최소 1개 이상이어야 합니다.");
        }
      },
      fetchCart() {
        const CartItem = JSON.parse(localStorage.getItem("CartItem"));
        if (CartItem) {
          this.cart = CartItem.map((item) => ({
            ...item,
            selected: false,
          }));
        }
      },
      RemoveItem(i) {
        this.$confirm('장바구니에서 삭제하시겠습니까?')
        .then(() => {
          this.cart = this.cart.filter(
              item => !(item.id === i.id && item.category === i.category)
          );
          localStorage.setItem('CartItem', JSON.stringify(this.cart));
          this.$alert(`${i.name} 상품이 삭제되었습니다!`);
        })
        .catch(() => {
          return
        });
      },
      clearCart() {
        localStorage.removeItem('CartItem');
        this.cart = [];
        this.$alert('장바구니를 비웠습니다!');
      },
      selectAll() {
        this.$confirm('장바구니에 모든상품을 구매 하시겠습니까?')
        .then(() => {
          this.cart.forEach((item) => {
            item.selected = true;
          });
        })
        .catch(() => {
          return
        });
      },
      processSelected() {
        const selectedItems = this.cart.filter((item) => item.selected);
        if (selectedItems.length > 0) {
          this.$alert(`${selectedItems.length}개의 상품이 주문되었습니다.`);
          console.log("주문된 상품:", selectedItems);
        } else {
          this.$alert("선택된 상품이 없습니다.");
        }
      },
    },
    created() {
      this.fetchCart();
    },
  }
</script>